import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
export function DashboardHomePage({ fixedHeightPaper } : any) {
  return (
    <Grid container spacing={3}>
      {/* Chart */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper className={fixedHeightPaper}><Typography component="h2">Thanks for visiting the Blugos portal. You will find the SMS import tool under the patient visits section.</Typography></Paper>
      </Grid>
      {/* Recent Deposits */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightPaper}>{/* <Deposits /> */}</Paper>
      </Grid>
      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper>{/* <Orders /> */}</Paper>
      </Grid>
    </Grid>
  );
}
