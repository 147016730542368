import { HomePage } from "./HomePage";
import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Login from "./Login";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { ApolloClient, InMemoryCache } from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import Dashboard from "./Dashboard";
import { StoreProvider } from "./Store";
import { WebSocketLink } from "apollo-link-ws";
import { HttpLink } from "apollo-link-http";
import { getMainDefinition } from "apollo-utilities";
import { split, ApolloLink, concat } from "apollo-link";

function App() {
  const history = useHistory();
  // Create an http link:
  const httpLink = new HttpLink({
    uri: "https://blugos.herokuapp.com/v1/graphql",
  });

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: `wss://blugos.herokuapp.com/v1/graphql`,
    options: {
      reconnect: true,
      connectionParams: () => ({
        Authorization: `Bearer ${localStorage.getItem("blugos-token")}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("blugos-token")}`,
        },
      }),
    },
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = localStorage.getItem("blugos-token");
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
    return forward(operation);
  });
  const location = useLocation();
  const currentPath = location.pathname;
  if (currentPath != "/login") {
    const token = localStorage.getItem("blugos-token");
    if (!token || token === "") {
      history.push("/login");
    }
  }

  const client = new ApolloClient({
    link: concat(authMiddleware, link),
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
      <StoreProvider>
        <Switch>
          <Route exact path="/">
            <HomePage logo={logo} />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
        </Switch>
      </StoreProvider>
    </ApolloProvider>
  );
}

export default App;
