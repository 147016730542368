import React, { FunctionComponent } from "react";
import { useLocalStore } from "mobx-react-lite";
//import { AsyncTrunk } from 'mobx-sync/lib/async';

export function createStore() {
  // note the use of this which refers to observable instance of the store
  return {
    importInformation: {
      excelHeaderRow: 0,
      visitIndex: 0,
      phoneIndex: 0,
      dateIndex: 0,
    },
  };
}

export type TStore = ReturnType<typeof createStore>;

export const StoreContext = React.createContext<TStore | null>(null);

export const StoreProvider: FunctionComponent = ({ children }) => {
  const store = useLocalStore(createStore);
  //const trunk = new AsyncTrunk(store, { storage: localStorage });
  //trunk.init();
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => {
  const store = React.useContext(StoreContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error("useStore must be used within a StoreProvider.");
  }
  return store;
};
