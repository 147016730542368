import React, { useState, FunctionComponent } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { LinearProgress } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import XLSX from "xlsx";

import {
  Input,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import readXlsxFile from "read-excel-file";
import { useStore } from "./Store";
import { observer } from "mobx-react-lite";
import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ADD_DATA_IN, ADD_DATA_INVariables } from "./types/ADD_DATA_IN";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

function getSteps() {
  return [
    "Select an excel file",
    "File Header Selection",
    "Map Excel File To Columns",
    "Finish Data Loading",
  ];
}
let excelData: [][];

let fileInput:
  | ((instance: unknown) => void)
  | React.RefObject<unknown>
  | null
  | undefined;

const getExcelColumnData = (headerRowIndex: number) => {
  if (excelData) {
    return excelData[headerRowIndex];
  } else {
    return [];
  }
};
let form: any;

type StepProps = {
  stepIndex: number;
  bubbleNextReady: React.Dispatch<React.SetStateAction<boolean>>;
};

const getDataTableColumns = () => {
  try {
    return Array.from(Array(excelData[0].length).keys()).map(
      (row, index) => `Column ${index + 1}`
    );
  } catch (e) {
    return [];
  }
};
const StepContent: FunctionComponent<StepProps> = observer(
  ({ stepIndex, bubbleNextReady }) => {
    const store = useStore();
    const [addData] = useMutation<ADD_DATA_IN, ADD_DATA_INVariables>(gql`
      mutation ADD_DATA_IN($data: [AppointmentLoader!]!) {
        loadAppointments(input: $data)
      }
    `);
    const [storing, setStoring] = useState(false);
    const excelFileChanged = (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      // @ts-ignore
      var files = event.target.files,
        f = files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        // @ts-ignore
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        const [firstSheetName] = workbook.SheetNames;
        const worksheet = workbook.Sheets[firstSheetName];

        const rows = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: "",
          dateNF: "m/d/yy",
          raw: false,
        });
        console.log(rows);
        excelData = rows as any;
        bubbleNextReady(true);
      };
      reader.readAsArrayBuffer(f);
    };

    switch (stepIndex) {
      case 0:
        return (
          <Input
            ref={fileInput}
            onChange={excelFileChanged}
            type="file"
          ></Input>
        );
      case 1:
        console.log(getDataTableColumns());
        console.log(excelData.slice(0, 8));
        return (
          <>
            <p>
              Choose the row that includes the headers of the data. Usually,
              this is the first row, but it may not be, based on the data you
              have imported.
            </p>
            <div>
              <MUIDataTable
                columns={getDataTableColumns()}
                data={excelData.slice(0, 8)}
                title="Header Selector"
                options={{
                  selectableRowsHeader: false,
                  selectableRows: "single",
                  onRowsSelect: (selectedRows) => {
                    store.importInformation.excelHeaderRow =
                      selectedRows[0].dataIndex;
                    bubbleNextReady(true);
                  },
                }}
              />
            </div>
          </>
        );
      case 2:
        bubbleNextReady(true);
        return (
          <form ref={form}>
            <p>
              Choose the columns in the excel document that represent the data
              needed for the marketing platform{" "}
            </p>
            <InputLabel id="visitIdLabel">Visit ID Number</InputLabel>
            <Select
              value={store.importInformation.visitIndex}
              onChange={(elem) => {
                //@ts-ignore
                const ind: number = elem.target.value;
                store.importInformation.visitIndex = ind;
              }}
              labelId="visitIdLabel"
              label
            >
              {getExcelColumnData(store.importInformation.excelHeaderRow).map(
                (element: any, index: number) => {
                  return <MenuItem value={index}>{element}</MenuItem>;
                }
              )}
            </Select>
            <InputLabel id="patientPhoneLabel">Patient Phone Number</InputLabel>
            <Select
              value={store.importInformation.phoneIndex}
              onChange={(elem) => {
                //@ts-ignore
                const ind: number = elem.target.value;
                store.importInformation.phoneIndex = ind;
              }}
              labelId="patientPhoneLabel"
              label
            >
              {getExcelColumnData(store.importInformation.excelHeaderRow).map(
                (element: any, index: number) => {
                  return <MenuItem value={index}>{element}</MenuItem>;
                }
              )}
            </Select>
            <InputLabel id="visitDateLabel">Visit Date</InputLabel>
            <Select
              value={store.importInformation.dateIndex}
              onChange={(elem) => {
                //@ts-ignore
                const ind: number = elem.target.value;
                store.importInformation.dateIndex = ind;
              }}
              labelId="visitDateLabel"
              label
            >
              {getExcelColumnData(store.importInformation.excelHeaderRow).map(
                (element: any, index: number) => {
                  return <MenuItem value={index}>{element}</MenuItem>;
                }
              )}
            </Select>
          </form>
        );
      case 3:
        const loadData = async () => {
          setStoring(true);
          const dataToPush = [];
          for (let dataRow of excelData.slice(
            store.importInformation.excelHeaderRow + 1
          )) {
            try {
              dataToPush.push({
                visitID: (dataRow[
                  store.importInformation.visitIndex
                ] as any).toString(),
                phoneNumber: (dataRow[
                  store.importInformation.phoneIndex
                ] as any).toString(),
                visitDate: (dataRow[
                  store.importInformation.dateIndex
                ] as any).toString(),
              });
            } finally {
              continue;
            }
          }
          await addData({
            variables: {
              data: dataToPush,
            },
          });
          bubbleNextReady(true);

          setStoring(false);
        };
        return (
          <>
            {storing && <LinearProgress />}

            <Typography variant="h5">
              Let's load your data! Hit the button below to load the data into
              the system.
            </Typography>
            <Button onClick={loadData} variant="outlined">
              Load Data Into Blugos
            </Button>
          </>
        );
      default:
        return <h1>Test</h1>;
    }
  }
);
type Props = {
  close: () => void;
};
const PatientVisitStepper: FunctionComponent<Props> = ({ close }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [nextReady, setNextReady] = useState(false);
  const steps = getSteps();
  if (form) {
    console.log(form);
  }
  const handleNext = () => {
    setNextReady(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button onClick={close}>Close</Button>
          </div>
        ) : (
          <div>
            <StepContent
              bubbleNextReady={setNextReady}
              stepIndex={activeStep}
            />
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button
                disabled={!nextReady}
                variant="contained"
                color="primary"
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientVisitStepper;
