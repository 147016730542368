import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Grid,
  Paper,
} from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { Navigation } from "@material-ui/icons";
import PatientVisitStepper from "./PatientVisitStepper";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { LOAD_APPT_STATISTICS } from "./types/LOAD_APPT_STATISTICS";
export function PatientVisits() {
  const [open, setOpen] = useState(false);

  const { loading, error, data } = useSubscription<LOAD_APPT_STATISTICS>(gql`
    subscription LOAD_APPT_STATISTICS {
      appointment_visits_statistics_agg {
        visit_date
        count
      }
    }
  `);
  if (data) {
    console.log(
      data?.appointment_visits_statistics_agg.map((val) => {
        return { x: val.visit_date, y: val.count };
      })
    );
  }
  return (
    <div>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper>
            {!data ? (
              <h1>Loading</h1>
            ) : (
              <Line
                data={{
                  labels: data?.appointment_visits_statistics_agg.map(val => val.visit_date),
                  datasets: [
                    {
                      label: 'Appointment Visits',
                      data: data?.appointment_visits_statistics_agg.map(
                        (val) => {
                          return { 'x': val.visit_date, y: val.count };
                        }
                      ),
                    },
                  ],
                }}
                width={100}
                height={200}
                options={{ maintainAspectRatio: false }}
              />
            )}
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper>{/* <Deposits /> */}</Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper>{/* <Orders /> */}</Paper>
        </Grid>
      </Grid>
      <Button onClick={() => setOpen(true)} variant="contained">
        <Navigation />
        Import Visits From Excel
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Excel Visit Importer</DialogTitle>
        <DialogContent>
          <PatientVisitStepper
            close={() => {
              setOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
