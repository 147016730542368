import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  CssBaseline,
  Avatar,
  Typography,
  Box,
  TextField,
  Button,
} from "@material-ui/core";
import {
  Error as ErrorIcon,
  LockOutlined as LockOutlinedIcon,
  Copyright,
} from "@material-ui/icons";
import { Formik, Form, Field } from "formik";
import { gql, ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { LOGIN, LOGINVariables } from "./types/LOGIN";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    verticalAlign: "middle",
    display: "inline-flex",
    color: "red",
  },
  errorBox: {
    display: "flex",
    minHeight: "50px",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  /* async function onSubmit(event) {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;
    event.target.username.value = "";
    event.target.password.value = "";
    await onLogin(username, password);
  } */

  const BLUGOS_GCP_client = new ApolloClient({
    link: new HttpLink({
      uri: "https://blugos-extension-dot-computid2.uc.r.appspot.com", // Server URL (must be absolute)
    }),
    cache: new InMemoryCache(),
  });

  const LOGIN_MUTATION = gql`
    mutation LOGIN($email: String, $password: String) {
      login(email: $email, password: $password)
    }
  `;

  const [loginMutation, { data }] = useMutation<LOGIN, LOGINVariables>(
    LOGIN_MUTATION,
    {
      client: BLUGOS_GCP_client,
    }
  );
  const [errorString, setErrorString] = useState("");

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1">{errorString}</Typography>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in to Blugos
        </Typography>
        <Box className={classes.errorBox}></Box>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={({ email, password }) => {
            loginMutation({ variables: { email, password } })
              .then(({ data, errors }) => {
                setErrorString("asdf");
                if (errors) {
                  setErrorString("error");
                } else {
                  localStorage.setItem("blugos-token", data?.login || "");
                  history.push(`/dashboard`);
                }
              })
              .catch((reason) => {
                console.log(reason);
                setErrorString(
                 reason.message
                );
              });
          }}
        >
          {({ submitForm, isSubmitting, handleChange, handleBlur, values }) => (
            <Form className={classes.form}>
              <TextField
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
